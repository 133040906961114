.header {
  color: var(--text);
  padding-bottom: 32px;
  z-index: 9999;
  position: relative;
}
@media (max-width: 768px) {
  .header {
    display: none;
  }
}
.header__title {
  font-weight: bold;
  color: var(--text);
}
.header__title h1 {
  font-size: 36px;
}
@media (max-width: 1200px) {
  .header__title h1 {
    font-size: 32px;
  }
}
@media (max-width: 991px) {
  .header__title h1 {
    font-size: 28px;
  }
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__content {
  display: flex;
  align-items: center;
}
.header__profile-block {
  display: flex;
  align-items: center;
}
.header__button-record {
  padding-right: 24px;
}
.header__profile-text {
  padding-right: 32px;
}
@media (max-width: 1200px) {
  .header__profile-text {
    padding-right: 16px;
  }
}
.header__profile-name {
  font-size: 20px;
  margin-bottom: 8px;
}
@media (max-width: 991px) {
  .header__profile-name {
    font-size: 14px;
  }
}
.header__profile-link {
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
}
@media (max-width: 991px) {
  .header__profile-link {
    font-size: 14px;
  }
}
.header__profile-photo {
  cursor: pointer;
  display: flex;
}
@media (max-width: 1200px) {
  .header__profile-photo {
    width: 64px;
    height: 64px;
  }
}
.header-mobile {
  display: none;
  margin: -32px -24px 0px -24px;
}
@media (max-width: 1024px) {
  .header-mobile {
    display: block;
  }
}
.header-mobile__container {
  display: flex;
  flex-direction: column;
}
.header-mobile__logo {
  height: 48px;
  margin-bottom: 12px;
}
.header-mobile__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.header-mobile__profile-block {
  display: flex;
  padding: 24px 24px 0px 24px;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  position: relative;
}
.header-mobile__profile-info {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.header-mobile__button {
  padding: 10px 24px;
  background-color: #EBEBEB;
}
.header-mobile__profile-name {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 12px;
}
.header-mobile__button .button-primary {
  width: 100%;
}
.header-mobile__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 24px;
  border-bottom: 1px solid #000;
}
.header-mobile__title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (max-width: 480px) {
  .header-mobile__title {
    font-size: 24px;
  }
}
@media (max-width: 410px) {
  .header-mobile__title {
    font-size: 18px;
  }
}
.header-mobile__filter {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999;
  border-radius: 12px 12px 0px 0px;
}
.header-mobile__filter.active {
  background-color: var(--primary);
}
.header-mobile__filter.active .icon_filter {
  background-position-x: right;
}

body {
    line-height: 1.25;
    overflow-x: hidden;
}

body input,
body textarea {
    font-size: 16px !important;
}

.main {
    display: flex;
    overflow-x: hidden;
}

.content {
    position: relative;
    padding: 48px;
    width: 100%;
    min-height: calc(100vh - 128px);
    margin-right: calc((100vw - 100%) * -1) !important;
    margin-left: 0 !important;
    box-sizing: border-box;
}

@media(max-width: 1140px) {

    .content {
        padding: 32px 32px 132px 32px;
        min-height: calc(100vh - 128px);
    }

}

@media(max-width: 991px) {

    .content {
        min-height: calc(100vh - 64px);
    }

}

.swipe-animation {
    transition: transform 0.25s ease;
}

@media(max-width: 768px) {

    .content {
        padding: 32px 24px 90px 24px;
        min-height: calc(100vh - 164px);
        box-sizing: border-box;
    }

}

.end_page {
    padding: 64px 0 40px 0;
    text-align: center;
    opacity: 0.25;
}

@media(max-width: 768px) {

    .end_page {
        padding: 48px 0 0 0;
    }

}
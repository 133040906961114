.filter {
  position: relative;
  z-index: 999;
  width: 100%;
  background-color: var(--primary);
  margin-bottom: 24px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
}
@media (max-width: 768px) {
  .filter {
    display: none;
    padding: 16px;
  }
  .filter.active-mobile {
    display: block;
    margin-bottom: 12px;
    top: -16px;
    border-radius: 12px 12px 12px 12px;
  }
}
.filter__container {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .filter__container {
    flex-direction: column;
  }
}
.filter__content {
  display: flex;
  width: 100%;
}
@media (max-width: 768px) {
  .filter__content {
    flex-direction: column;
  }
}
.filter__content > * {
  width: calc(100% / 4);
}
@media (max-width: 768px) {
  .filter__content > * {
    width: 100%;
  }
}
.filter__icon {
  padding-right: 24px;
  padding-left: 8px;
  filter: invert(var(--icons_primary_inverse));
}
@media (max-width: 768px) {
  .filter__icon {
    display: none;
  }
}
.filter-select {
  padding: 8px 24px;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  color: var(--text_inverse);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  z-index: 5;
}
@media (max-width: 768px) {
  .filter-select {
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    margin-top: 24px;
    padding-top: 24px !important;
    padding: 0px;
  }
  .filter-select_one {
    border-top: none;
    margin-top: 0px;
    padding-top: 0px !important;
    padding: 0px;
  }
}
.filter-select__container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}
.filter-select__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.filter-select__title {
  padding-bottom: 16px;
}
.filter-select__title.bold {
  font-weight: bold;
}
@media (max-width: 768px) {
  .filter-select__title {
    padding-bottom: 8px;
  }
}
.filter-select .filter-select_options,
.filter-select .filter-select_options .filter-select_option {
  display: none;
}
.filter-select .filter-select_options.active {
  display: block;
  background-color: #fff;
  color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
  z-index: 999;
  overflow: hidden;
  top: calc(100% + 16px);
}
@media (max-width: 768px) {
  .filter-select .filter-select_options.active {
    top: 0;
    border-radius: 4px;
  }
}
.filter-select .filter-select_options.active .filter-select_option {
  padding: 16px 16px;
}
.filter-select .filter-select_options.active .filter-select_option:hover:not(.disabled) {
  background-color: var(--primary);
  color: #fff;
}
.filter-select_option.active.disabled {
  opacity: 0.5;
}
.filter-select .filter-select_options.active .filter-select_option {
  display: block;
}
.filter-select__button-close {
  display: none;
  filter: invert(var(--icons_primary_inverse));
}
.filter-select__button-close.active {
  display: block;
}
.filter-select.active {
  z-index: 9999;
}
.my-notes .filter__content .filter-select {
  height: 60px;
}
@media (max-width: 768px) {
  .my-notes .filter__content .filter-select {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin-bottom: 24px;
    padding-bottom: 24px !important;
    padding: 0px;
    border-top: none;
    margin-top: 0px;
    padding-top: 0px !important;
  }
}
.filter-plug {
  padding: 8px 24px;
  border-left: 1px solid rgba(255, 255, 255, 0.25);
  color: var(--text_inverse);
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .filter-plug {
    display: none;
  }
}
.filter-plug div {
  background-color: var(--text_inverse);
  opacity: 0.5;
  height: 16px;
}
.filter-plug div:first-child {
  width: 50%;
  margin-bottom: 16px;
}
.filter-plug div:last-child {
  width: 100%;
}
